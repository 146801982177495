.tile-container-ug-coverage {
  display: flex;
}

.tile-ug-coverage {
  width: 24%;
  border: .0625rem solid #b5b5b5e3;
  min-height: 10rem;
  border-radius: 1rem;
  background-color: #ffff;
}

.tile-margin-ug-coverage {
  margin-right: 1rem;
}

.item-chart-container {
  padding-top: 1rem;
  display: flex;
}

.item-chart {
  width: 100%;
  border: .0625rem solid #b5b5b5e3;
  min-height: 600px;
  border-radius: .5rem;
  background-color: #ffff;
  padding: 1rem;

  .cds--cc--title p.title {
    padding-bottom: 1rem;
  }
}

.tile-coverage {
  width: 50%;
  border: .0625rem solid #b5b5b5e3;
  min-height: 10rem;
  border-radius: 1rem;
  background-color: #ffff;
  padding: 1rem;
}

.item-chart-left {
  margin-right: 1rem;
}

.metrics-body {
  background-color: #e0e0e08a;
  min-height: calc(100vh - 54px);
}


.rights-panel {
  margin-bottom: .3rem;
}

.tile-header {
  padding: 1rem;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
}

.tile-icon {
  color: #009d9a;
}

.emr-details-table {
  padding: 0.5rem 0 0 1rem !important;
}

.tile-items-container {
  display: flex;
  justify-content: space-between;
}

.tile-item-value {
  padding-top: 3.1rem;
  font-size: 21px;
}

.td-text-align {
  text-align: left;
}

.emr-details-table td {
  font-size: 13px;
  padding-bottom: 6px;
}

.emr-version {
  padding-left: 5px;

  svg {
    margin: 0 0 -0.2rem 0;
    color: #009d9a;
  }
}

.non-functional-emr {
  padding-left: 5px;

  svg {
    margin: 0 0 -0.2rem 0;
    color: darkgray;
  }
}

.arrow-icon {
  fill: #009d9a !important;
  margin-left: .2rem;
  margin-top: .1rem;
}

.tile-bottom-style {
  display: flex;
  justify-content: space-between;
}

.view-button {
  margin-top: 1.8rem !important;
  color: #009d9a !important;
}

.switcher-container-width {
  width: 48.8%;
}

.date-container-width {
  width: 51.2%;
}
.switcher-date-container {
  display: flex;
  justify-content: end;
}

.date-input-container {
  margin-top: 2.5rem
}

.item-chart-donut {
  padding: 1rem;

  svg {
    margin-left: 8rem;
  }
}

.section-wrapper {
  margin-left: 15rem;
}

.level-style {
  padding-top: 3rem;
  font-size: small;
}

.level-value-style {
  margin-right: 0.5rem;
  color: #009d9a;
  font-weight: bold;
}

.level-text {
  font-size: small;
}

.level {
  font-size: 14px;
  margin: 0 0.5rem 0 0;
}

.td-details-value {
  font-weight: bold;
}

.tile-header-style {
  border-bottom-color: #d6d5d5;
  border-bottom-width: 1px;
  /*border-bottom-style: solid;*/
  margin-bottom: 2%;
}

.header-text-color {
  margin: 1% 0 0 5%;
  font-size: 1.3em
}

.tile-item-coverage {
  padding-right: 2rem;
  font-size: 3em;
  color: #b9b7b7;
}

.coverage-header-container {
  display: flex;
  justify-content: space-between;
}

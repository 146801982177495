.update-transaction {
  align-items: center;
  border-color: #009d9a;
  color: #009d9a;
  font-weight: bold;

  svg {
    margin-right: 0.5rem;
  }
}

.metrics-date-picker {
  display: flex;
  width: fit-content;
  margin: -3.5rem 0.5rem 0 auto;

  .cds--date-picker-container, .cds--date-picker-container input {
    width: 15rem
  }

  .cds--date-picker-container {
    margin-right: 1rem !important;
  }

  .cds--btn--tertiary:hover {
    background-color: #009d9a;
  }

  .cds--btn--tertiary:active, .cds--btn--tertiary:focus {
    border-color: transparent;
    background-color: #009d9a;
    color: #ffff;
  }
  .cds--btn--tertiary:focus {
    box-shadow: inset 0 0 0 1px #009d9a, inset 0 0 0 2px var(--cds-background, #ffffff);
  }
}

.tile-container {
  padding-top: 2rem;
  display: flex;
}

.tile {
  width: 25%;
  border: .0625rem solid #b5b5b5e3;
  min-height: 10rem;
  border-radius: 1rem;
  background-color: #ffff;
}

.tile-margin {
  margin-right: 1rem;
}

.item-chart-container {
  padding-top: 1rem;
  display: flex;
}

.item-chart {
  width: 100%;
  border: .0625rem solid #e0e0e0;
  min-height: 600px;
  border-radius: .5rem;
  background-color: #ffff;
  padding: 1rem;

  .cds--cc--title p.title {
    padding-bottom: 1rem;
  }
}

.rights-panel {
  margin-bottom: .3rem;
}

.tile-header {
  padding: 1rem;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
}

.tile-icon {
  color: #009d9a;
}

.emr-details-table {
  padding: 1rem 0 0 1rem;
}

.tile-items-container {
  display: flex;
  justify-content: space-between;
}

.tile-item-value {
  padding-top: 2.8rem;
  font-size: 25px;
}

.poc-details-table td {
  font-size: 15px;
  font-family: sans-serif;
  padding-bottom: 6px;
}

.emr-version {
  padding-left: 5px;

  svg {
    margin: 0 0 -0.2rem 0;
    color: #009d9a;
  }
}

.non-functional-emr {
  padding-left: 5px;

  svg {
    margin: 0 0 -0.2rem 0;
    color: darkgray;
  }
}

.tile-bottom-style {
  display: flex;
  justify-content: space-between;
}

.switcher-container-width {
  width: 48.8%;
}

.date-container-width {
  width: 51.2%;
}
.switcher-date-container {
  display: flex;
}

.date-input-container {
  margin-top: 2.5rem
}
